import React, { useEffect, useState } from 'react';
import { oneOfType, func, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

import useUser from 'Hooks/useUser';
import useRouter from 'Hooks/useRouter';
import { API_PASSWORD_RECOVERY, API_AUTHENTICATION_LOGIN_VERIFY } from 'Constants';

const AuthHOC = ({ children }) => {
  const { data: user, userLogin, setUserData } = useUser();
  // const { user, userLogin, userMutate } = useContextSelector(UserContext, data => ({
  //   user: data.data,
  //   userLogin: data.userLogin,
  //   userMutate: data.mutate,
  // }));
  const { push, location } = useRouter();
  const { t } = useTranslation();

  const [state, setState] = useState({
    username: '',
    password: ''
  });
  const [recovered, setRecovered] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [err, setErr] = useState('');

  useEffect(() => {
    if (
      user?.loggedIn
      && user?.userId
      && success
    ) {
      setSuccess(false);
      setLoading(false);
    }
  }, [user?.userId, success]);

  useEffect(() => {
    if (user?.sessionExpired) {
      setErr(t('common.sessionExpired'));
    } else {
      setErr(err);
    }
  }, [user?.sessionExpired]);

  const handleOnLogin = async e => {
    e.preventDefault();
    const formEl = e.target.closest('form');

    if (!formEl.reportValidity()) {
      return;
    }

    try {
      setLoading(true);
      setErr('');
      const response = await userLogin({ ...state, _remember_me: rememberMe });

      // if (response?.success && response?.twoFactorAuthenticationRequired) {
      //   userMutate({ ...user, isTwoFactorAuthenticationVerified: false });
      // }

      if (response?.success && response?.twoFactorAuthenticationRequired === false) {
        window.location.href = '/mfa';
      }

      if (response?.error) {
        setErr(response.message);
      }
    } catch (error) {
      setSuccess(false);
      setErr(error?.info?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = type => event => {
    setErr('');
    setState(prevState => ({
      ...prevState,
      [type]: event.target.value
    }));
  };

  const handleRememberMe = checked => {
    setRememberMe(checked);
  };

  const handleError = errValue => {
    setErr(errValue);
  };

  const handleOnRecoverPassword = async e => {
    e.preventDefault();
    const formEl = e.target.closest('form');

    if (!formEl.reportValidity()) {
      return;
    }

    try {
      setRecovered(false);
      setLoading(true);
      setErr('');
      const response = await fetch(API_PASSWORD_RECOVERY, {
        method: 'POST',
        body: JSON.stringify({ email: state.recovery_email })
      });
      if (
        response.status < 299
        && response.status >= 200
      ) {
        setRecovered(true);
      } else {
        const body = await response.json();
        setErr(body || t('profile.passwordRecoveryError', { email: state.recovery_email }));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnMFASubmit = () => {
    setLoading(true);
    setErr('');
    fetch(API_AUTHENTICATION_LOGIN_VERIFY, {
      method: 'POST',
      body: JSON.stringify({ code: verificationCode })
    })
      .then(() => {
        setSuccess(true);
        setUserData({ isTwoFactorAuthenticationVerified: true });
        const locationState = location?.state || {};
        let redirectTo = locationState.pathname;
        if (!redirectTo) {
          redirectTo = location.pathname;
        }
        if (redirectTo?.includes('login')) {
          redirectTo = null;
        }
        if (!redirectTo) {
          // send to static dashboard page
          // navigate('/');
          window.location.href = '/';
        }

        push({
          pathname: redirectTo,
          search: location?.state?.search || location.search,
          hash: location?.state?.hash || location.hash
        });
      })
      .catch(error => {
        setSuccess(false);
        setErr(error?.info?.message || error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const propsToPass = {
    username: state.username,
    password: state.password,
    recoveryEmail: state.recovery_email,
    loading,
    sessionExpired: !!user?.sessionExpired,
    rememberMe,
    error: err,
    success,
    recovered,
    verificationCode,
    setVerificationCode,
    onRecoverPasswordSubmit: handleOnRecoverPassword,
    onRemember: handleRememberMe,
    onChange: handleOnChange,
    onSubmit: handleOnLogin,
    onError: handleError,
    onMFASubmit: handleOnMFASubmit,
  };

  if (typeof children === 'function') {
    return children(propsToPass);
  }

  return React.cloneElement(children, propsToPass);
};

AuthHOC.propTypes = {
  children: oneOfType([func, node]),
};

export default AuthHOC;
