import React, { useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';
import { node } from 'prop-types';

import { trackEvent } from 'Utils/analytics';

export const defaultModalsState = {
  EDIT_WISHLIST: {
    key: 'EDIT_WISHLIST',
    state: {
      open: false
    }
  },
  CREATE_WISHLIST: {
    key: 'CREATE_WISHLIST',
    state: {
      open: false
    }
  },
  MOVE_TO_WISHLIST: {
    key: 'MOVE_TO_WISHLIST',
    state: {
      open: false
    }
  },
  ADD_TO_WISHLIST: {
    key: 'ADD_TO_WISHLIST',
    state: {
      open: false
    }
  },
  DELETE_WISHLIST: {
    key: 'DELETE_WISHLIST',
    state: {
      open: false
    }
  },
  NOTIFICATIONS: {
    key: 'NOTIFICATIONS',
    state: {
      open: false
    }
  },
  CROSS_SALES: {
    key: 'CROSS_SALES',
    state: {
      open: false
    }
  },
  QUICK_VIEW: {
    key: 'QUICK_VIEW',
    state: {
      open: false
    }
  },
  PRODUCT_PICTURES: {
    key: 'PRODUCT_PICTURES',
    state: {
      open: false
    }
  },
  STOCK_ALERT: {
    key: 'STOCK_ALERT',
    state: {
      open: false
    }
  },
  RESET_MFA_METHOD: {
    key: 'RESET_MFA_METHOD',
    state: {
      open: false
    }
  }
};

export const ModalsContext = createContext({ ...defaultModalsState });

const ModalsProvider = ({ children }) => {
  const [modalState, setModalState] = useState({ ...defaultModalsState });

  const operators = useMemo(() => ({
    resetModal(key) {
      setModalState(prevState => {
        if (key in prevState) {
          return {
            ...prevState,
            [key]: { ...defaultModalsState[key] }
          };
        }
        return prevState;
      });
    },
    setState(key, state) {
      setModalState(prevState => {
        if (key in prevState) {
          return {
            ...prevState,
            [key]: {
              ...prevState[key],
              state: {
                ...prevState[key].state,
                ...state
              }
            }
          };
        }
        return prevState;
      });
    },
    openModal(key) {
      trackEvent(`opened_${key.toLowerCase()}_modal`);
      setModalState(prevState => {
        if (
          key in prevState
          && !prevState[key].state.open
        ) {
          return {
            ...prevState,
            [key]: {
              ...prevState[key],
              state: {
                ...prevState[key].state,
                open: true
              }
            }
          };
        }
        return prevState;
      });
    },
    closeModal(key) {
      trackEvent(`closed_${key.toLowerCase()}_modal`);
      setModalState(prevState => {
        if (
          key in prevState
          && prevState[key].state.open
        ) {
          return {
            ...prevState,
            [key]: {
              ...prevState[key],
              state: {
                ...prevState[key].state,
                open: false
              }
            }
          };
        }
        return prevState;
      });
    }
  }), [setModalState]);

  const memoizedContextValue = useMemo(
    () => ({
      modalState,
      getState: key => modalState[key]?.state || null,
      ...operators
    }),
    [modalState, operators]
  );

  return (
    <ModalsContext.Provider value={ memoizedContextValue }>
      { children }
    </ModalsContext.Provider>
  );
};

ModalsProvider.propTypes = {
  children: node
};

export default ModalsProvider;
