import amplitude from 'amplitude-js';
import { memoize } from 'lodash';

const initialize = memoize(() => {
  const key = process.env.AMPLITUDE_API_KEY;
  if (!key) return;

  amplitude.getInstance().init(key, undefined, {
    // optional configuration options
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true,
    defaultTracking: {
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
});

export const trackEvent = (name, data) => {
  initialize();

  function getPageNameFromUrl(url) {
    const urlPatterns = [
      { pattern: /^\/$/, pageName: 'dashboard_page' },
      { pattern: /^\/orders$/, pageName: 'orders_page' },
      { pattern: /^\/orders\/[^/]+$/, pageName: 'order_details_page' },
      { pattern: /^\/products\/[^/]+\/pd\/[^/]+$/, pageName: 'product_details_page' },
      { pattern: /^\/reports\/payments$/, pageName: 'reports_payments_page' },
      { pattern: /^\/reports\/invoices$/, pageName: 'reports_invoices_page' },
      { pattern: /^\/reports\/unpaid-invoices$/, pageName: 'reports_unpaid-invoices_page' },
      { pattern: /^\/reports\/balance$/, pageName: 'reports_balance_page' },
      { pattern: /^\/lists\/[^/]+$/, pageName: 'wishlist_page' },
      { pattern: /^\/rma$/, pageName: 'rma_page' },
      { pattern: /^\/users$/, pageName: 'users_page' },
      { pattern: /^\/addresses$/, pageName: 'addresses_page' },
      { pattern: /^\/account\/profile$/, pageName: 'account_profile_page' },
      { pattern: /^\/account\/settings$/, pageName: 'account_settings_page' },
      { pattern: /^\/account\/info$/, pageName: 'account_info_page' },
    ];

    let matchedPage = 'unknown_page';

    urlPatterns.forEach(({ pattern, pageName }) => {
      if (pattern.test(url)) {
        matchedPage = pageName;
      }
    });

    return matchedPage;
  }

  amplitude.getInstance().logEvent(name, { ...data, page: getPageNameFromUrl(window.location.pathname) });
};

export const setUserForTracking = (id, impersonated) => {
  initialize();

  amplitude.getInstance().setUserId(id);
  amplitude.getInstance().setUserProperties({ impersonated });
};
